import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Card,
  Box,
  Switch,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  CardHeader,
  Divider,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import UserServices from "../../services/UserServices";
import settingService from "../settings/settingServices";
import ServerSideTable from "../serverSideTable";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function Managers() {
  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(0);
  const [checked, setChecked] = React.useState([]);
  const [leftName, setLeftName] = useState([]);
  const [rightName, setRightName] = useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [roleVeiw, setRoleVeiw] = useState("");
  const [country, setCountry] = useState([]);
  const [moduleIds, setModulesIds] = useState([]);
  const [managerIds, setManagerIds] = useState("");

  const [loadingAddManager, setLoadingAddManager] = useState(false);
  const [loadingUpdateModule, setLoadingUpdateModule] = useState(false);
  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);
  const [loadingUpdateAssociation, setLoadingUpdateAssociation] =
    useState(false);

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //-------------Get Users API------------

  function getCompanies(e) {
    setLoading(false);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
  }

  function getUsers() {
    setLoading(true);
    UserServices.ManagerList(page, pageSize, roleVeiw)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        const { users, totalPages, totalElements } = response.data.data;
        console.log(
          "Manage Managers, list",
          users,
          totalPages,
          totalElements,
          response.data.data
        );
        setUserData(users);
        setTotalItems(totalElements);
        setCount(totalPages);
      })
      .catch((error) => {
        console.log("error", error);
        setCount(0);
      })
      .finally((response) => {
        handleToggleAccessButton();
        setLoading(false);
      });
  }

  function adduser() {
    debugger;
    setLoadingAddManager(true);
    const selectedModuleIds = modules
      .filter((module) => module.isChecked)
      .map((module) => module.moduleId);

    UserServices.AddManager({
      ...data,
      roles: [
        {
          id: 4,
          name: "ROLE_SUB_ADMIN",
        },
      ],
      moduleIds: selectedModuleIds,
      // id: 0,
    })
      .then((response) => {
        console.log("ct_response", response);
        setShow(false);
        getUsers();
        setData(request);
        updateAssociation(response.data.data);
        getAssoicationRightSide(response.data.data.id);
        getAssoicationLeftSide(response.data.data.id);
        setModuleAccessModel(false);
        setLoadingAddManager(false);
        // console.log("country_ID", response.data.data.id);
      })
      .catch((e) => {
        console.log(e);
        setLoadingAddManager(false);
        if (e.response.status == 400) {
          setOpen(true);
          setMessage(e.response.data.message);
        }
        setLoad(false);
      });
  }

  useEffect(() => {
    getCompanies();
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    if (page !== undefined) {
      getUsers();
    }
  }, [page, pageSize, roleVeiw]);

  const request = {
    id: "",
    name: "",
    email: "",
    mobile: "",
    roles: "",
    phoneCode: "",
    companyName: "",
    countryId: "",
  };

  // ------------------ get Country List-------------
  const getCountryList = () => {
    debugger;

    UserServices.userCountryList()
      .then((response) => {
        console.log("country_list", response.data);
        const { data } = response.data;
        setCountry(data);
        console.log("country_response", data);
        console.log("country_response_data", response.data.data[0].id); //fetching ID from response
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  //default call
  useEffect(() => {
    getCountryList();
  }, []);

  const [data, setData] = useState(request);
  const [updateData, setUpdateData] = useState(request);

  const columns = [
    // {
    //   field: "user",
    //   headerName: "User Type",
    //   width: 100,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    //   renderCell: (param) => {
    //     return param?.row?.roles[0]?.name == "ROLE_DISTRIBUTER"
    //       ? "D"
    //       : param?.row?.roles[0]?.name == "ROLE_CLIENT"
    //       ? "P"
    //       : "Admin";
    //   },
    // },
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    // {
    //   field: "companyName",
    //   headerName: "Company Name",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        function approved(e) {
          const formData = new FormData();
          formData.append("status", e);
          console.log("User param Admin", param);
          swal({
            title: "Are you sure?",
            text: `You want to ${param.row.status ? "Block" : "Unblock"} ${
              param.row.name
            }`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              UserServices.userBlock(e, param.row.id).then((response) => {
                console.log(response);
                getUsers();
                swal("Successfull", {
                  icon: "success",
                });
              });
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary mx-1"
              onClick={() => {
                debugger;
                updateValue(param.row);
              }}
            >
              <i className="bi bi-pencil"></i>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                updateAssociation(param.row);
                getAssoicationRightSide(param.row.id);
                getAssoicationLeftSide(param.row.id);
              }}
            >
              <i className="bi bi-diagram-3-fill"></i>
            </button>

            <button
              type="button"
              className="btn btn-primary mx-1"
              onClick={() => handleUserModule(param.row.id)}
            >
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </button>
            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#view"> <i className="bi bi-eye"></i></button> */}
          </>
        );
      },
    },
  ];

  const handleModuleAccess = () => {
    setModuleAccessModel(true);
  };
  //-------------Add Users API------------
  const [show, setShow] = useState(false);
  const [showManager, setShowManager] = useState(false);
  const [moduleAccessModel, setModuleAccessModel] = useState(false);
  const [validated, setValidated] = useState(false);

  const [checkedModules, setCheckedModules] = useState([]);

  const [updateModuleAccessModel, setUpdateModuleAccessModel] = useState(false);

  const handleUpdateModuleAccessModelClose = () => {
    setUpdateModuleAccessModel(false);
  };

  const handleClose = () => {
    setShow(false);
    setData([]);
  };

  const handleManagerClose = () => {
    setShowManager(false);
  };
  const handleModuleAccessModelClose = () => {
    setModuleAccessModel(false);
    updateModules();
  };

  const handleModuleAccessModelShow = () => {
    setModuleAccessModel(true);
  };

  // const UserModuleAccess = (userId) => {
  //   setModuleAccessModel(true);
  //   UserServices.userModules(userId);
  //   console.log("User Module access thing: ", userId);

  // };
  const UserModuleAccess = async (userId) => {
    console.log("UserModuleAccess", userId);
    try {
      const response = await UserServices.userModules(userId); // Fetch user's module data
      const userModuleIds = response.data.data.map((module) => module.moduleId); // Extract user's checked module IDs

      // Update the `isChecked` property for each module based on the user's data
      const updatedModules = modules.map((module) => ({
        ...module,
        isChecked: userModuleIds.includes(module.moduleId),
      }));

      setModules(updatedModules); // Update state with the modified modules
      console.log(
        "Updated Modules with User's Checked Modules:",
        updatedModules
      );
    } catch (error) {
      console.error("Error fetching user modules:", error);
    }
  };

  const handleUserModule = (id) => {
    setManagerIds(id);
    setUpdateModuleAccessModel(true);
    UserModuleAccess(id);
  };

  const handleShow = () => setShow(true);

  const handleManagerShow = () => setShowManager(true);

  // const handleToggleAccess = (moduleId) => {
  //   setCheckedModules((prevChecked) => {
  //     // Toggle module ID in the array
  //     if (prevChecked.includes(moduleId)) {
  //       return prevChecked.filter((id) => id !== moduleId);
  //     } else {
  //       return [...prevChecked, moduleId];
  //     }
  //   });
  // };

  // function adduser() {
  //   debugger;

  //   UserServices.adduser({
  //     ...data,
  //     roles: [
  //       {
  //         id: data.roles,
  //         name: data.roles == 1 ? "ROLE_DISTRIBUTER" : "ROLE_CLIENT",
  //       },
  //     ],
  //   })
  //     .then((response) => {
  //       console.log("ct_response", response);
  //       setShow(false);
  //       getUsers();
  //       setData(request);
  //       updateAssociation(response.data.data);
  //       getAssoicationRightSide(response.data.data.id);
  //       getAssoicationLeftSide(response.data.data.id);
  //       // console.log("country_ID", response.data.data.id);
  //     })
  //     .catch((e) => {
  //       console.log(e);

  //       if (e.response.status == 400) {
  //         setOpen(true);
  //         setMessage(e.response.data.message);
  //       }
  //       setLoad(false);
  //     });
  // }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (data.roles !== "") {
        if (data.name !== "" && data.email !== "" && data.mobile !== "") {
          adduser();
        } else {
          setOpen(true);
          setMessage("Please Enter All Field");
        }
      } else {
        setOpen(true);
        setMessage("Please Select Role First");
      }
    }

    setValidated(true);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteUser, setDeleteUser] = useState("");

  const deletehandle = () => {
    UserServices.deleteUser(deleteId).then((response) => {
      console.log(response);
      setOpenDelete(false);
      getUsers();
    });
  };

  const deletehandleClose = () => {
    setOpenDelete(false);
  };

  //---------------------------------Update--------------------------

  const [updateShow, setUpdateShow] = useState(false);
  const updateValue = (datas) => {
    debugger;
    console.log("updateData", datas);
    //setUpdateData({ ...updateData, ...datas })
    setUpdateData({
      ...updateData,
      id: datas.id,
      name: datas.name,
      email: datas.email,
      mobile: datas.mobile,
      roles: datas.roles,
      phoneCode: datas.phoneCode,
      companyName: datas.companyName,
      countryId: datas.country.id,
    });
    setUpdateShow(true);
  };

  const [associationShow, setAssociationShow] = useState(false);
  const [userState, setUserState] = useState();
  const updateAssociation = (datas) => {
    debugger;
    setUserState(datas);
    setAssociationShow(true);
    console.log("updateData", updateData);
  };

  function updateUser() {
    setLoadingUpdateUser(true);
    UserServices.updateUser(
      { ...updateData, roles: updateData.roles },
      updateData.id
    )
      .then((response) => {
        // setUpdateShow(false);
        setLoadingUpdateUser(false);
        updateHandleClose();
        setOpen(true);
        setMessage(response.data.message);
        getUsers();
        setData(request);
      })
      .catch((error) => {
        setLoadingUpdateUser(false);
        if (error.response.status == 400) {
          setOpen(true);
          setMessage(error.response.data.message);
        }
        if (error.response.status == 409) {
          setOpen(true);
          setMessage(error.response.data.message);
        }
        setLoad(false);
      });
  }
  const updateHandleSubmit = (event) => {
    debugger;

    console.log("countryId", updateData);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (
        updateData.companyName !== "" &&
        updateData.name !== "" &&
        updateData.mobile !== "" &&
        updateData.email !== "" &&
        updateData.countryId !== ""
      ) {
        updateUser();
        // updateHandleClose(); // Close the modal after submitting
      } else {
        setOpen(true);
        setMessage("Please Enter All Fields");
      }
    }

    setValidated(true);
  };

  const updateHandleSubmitAssoication = (values) => {
    debugger;
    setLoad(true);
    setLoadingUpdateModule(true);
    console.log("values", values);
    console.log(userState.id);
    UserServices.updateAssoication({ userId: userState.id, listIds: values })
      .then(() => {
        console.log("Updated updateHandleSubmitAssoication");
        setLoad(false);
        setOpen(true);
        setMessage("Association has been Updated");
        setLoadingUpdateModule(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingUpdateModule(false);
        setLoad(false);
      });
  };
  const updateHandleClose = () => {
    setUpdateShow(false);
    // setUpdateData([])
  };
  const associationShowHandleClose = () => {
    setAssociationShow(false);
    // setUpdateData([])
  };
  //---------------------------------Update--------------------------

  //---------------------------------Assoication List--------------------------

  const getAssoicationLeftSide = (id) => {
    UserServices.getAssoicationLeftSide(id)
      .then((response) => {
        console.log(response.data.data);
        var leftData = [];
        var leftName1 = [];
        response.data.data?.forEach((option) => {
          leftData.push(option.id);
          // leftName.push({ name: option.name, id: option.id });
          leftName1[option.id] = { name: option.name, id: option.id };
        });
        setLeft(leftData);

        setLeftName(leftName1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssoicationRightSide = (id) => {
    UserServices.getAssoicationRightSide(id)
      .then((response) => {
        var rightData = [];
        var rightName1 = [];
        response.data.data?.forEach((option) => {
          rightData.push(option.id);
          rightName1[option.id] = { name: option.name, id: option.id };
        });
        setRight(rightData);
        setRightName(rightName1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    debugger;
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    debugger;
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    debugger;
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const getNameMethod = (param) => {
    // return "item-" + param;
    console.log("leftName Array", leftName);
    // var leftName = leftName.filter((value) => value.param);
    let setname = leftName.find((o) => o?.id === param);
    if (setname == "" || setname == undefined) {
      setname = rightName.find((o) => o?.id === param);
    }
    // return setname?.name + " (" + param + ")";
    return setname?.name;
  };

  const customList = (items, isLeftList) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        // title={`title`}
        subheader={`List of ${
          isLeftList
            ? displayedRole === "PRINCIPAL"
              ? "Distributor"
              : "Principal"
            : "Assigned " +
              (displayedRole === "PRINCIPAL" ? "Distributor" : "Principal")
        }`}
      />
      <Divider />
      <List
        sx={{
          width: 270,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>

              <ListItemText
                id={labelId}
                // primary={`${
                //   leftName[index]?.id === value ? leftName[index]?.name : ""
                // }- (${value})`}
                primary={getNameMethod(value)}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const roleName = userState?.roles[0]?.name;

  // Define a mapping for role names
  const roleMappings = {
    ROLE_DISTRIBUTER: "DISTRIBUTOR",
    ROLE_CLIENT: "PRINCIPAL",
    // Add more mappings as needed for other role names
  };

  // Display the mapped role name
  const displayedRole = roleMappings[roleName] || roleName;

  const handleRoleView = (e) => {
    const roleView = e.target.value;
    setRoleVeiw(roleView);
    setPage(0);
  };

  const [countryCode, setCountyCode] = useState([]);
  const [countryName, setCountryName] = useState([]);

  const getAllCountriesList = async () => {
    try {
      const response = await settingService.CurrencyCountriesList();
      const countries = response.data.data;

      // Sort countries by name in ascending order
      const sortedCountriesByName = [...countries].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCountryName(sortedCountriesByName);

      // Sort countries by phone code in ascending order
      const sortedCountriesByPhoneCode = [...countries].sort((a, b) =>
        a.phonecode.localeCompare(b.phonecode)
      );
      setCountyCode(sortedCountriesByPhoneCode);

      console.log("countries-list-by-name", sortedCountriesByName);
      console.log("countries-list-by-phone-code", sortedCountriesByPhoneCode);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllModules = async () => {
    try {
      const response = await UserServices.getModules(); // API call
      console.log("Module List data", response.data.data);

      const updatedModules = (response.data.data || []).map((module) => ({
        ...module,
        isChecked: false, // Initialize with false
      }));
      setModules(updatedModules);
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
  };

  useEffect(() => {
    // Fetch initial data
    getAllCountriesList();
    getAllModules();
  }, []);

  console.log("countryName", countryName);
  const valueOnChange = (event) => {
    debugger;
    const { name, value } = event.target;
    const phoneRegex = /^[0-9\b]*$/;

    if (name === "countryId") {
      // Handle country selection
      const selectedId = value;
      const selectedCountry = countryName?.find(
        (country) => country?.id === parseInt(selectedId)
      );

      if (selectedCountry) {
        setData({
          ...data,
          countryId: selectedId,
          phoneCode: selectedCountry.phonecode, // Update phone code based on selected country
        });
      }
    } else if (name === "mobile") {
      // Handle phone number input
      if (phoneRegex.test(value)) {
        setData({ ...data, [name]: value });
      }
    } else {
      // Handle other inputs
      setData({ ...data, [name]: value });
    }
  };

  const updatevalueOnChange = (event) => {
    const { name, value } = event.target;

    const phoneRegex = /^[0-9\b]+$/;
    if (name === "countryId") {
      // Handle country selection
      const selectedId = value;
      const selectedCountry = countryName?.find(
        (country) => country?.id === parseInt(selectedId)
      );

      if (selectedCountry) {
        setUpdateData((prevState) => ({
          ...prevState,
          phoneCode: selectedCountry.phonecode,
          countryId: selectedId,
        }));
      }
    } else if (name === "mobile") {
      if (value === "" || phoneRegex.test(value)) {
        setUpdateData((prevState) => ({ ...prevState, [name]: value }));
      }
    } else if (name === "roles") {
      setUpdateData((prevState) => ({ ...prevState, [name]: [{ id: value }] }));
    } else {
      setUpdateData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // Log updateData state after update
  useEffect(() => {
    console.log("updateData after change", updateData);
  }, [updateData]);

  console.log("updatedData", updateData);

  // List of modules with access state and icons
  const [modules, setModules] = useState([]);

  // Toggle the access for each module
  // const handleToggleAccessButton = (moduleName) => {
  //   setModules((prevModules) =>
  //     prevModules.map((module) =>
  //       module.name === moduleName
  //         ? { ...module, access: !module.access }
  //         : module
  //     )
  //   );
  // };

  const handleToggleAccessButton = (moduleId) => {
    console.log("Toggling access for:", moduleId);
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.moduleId === moduleId
          ? { ...module, isChecked: !module.isChecked } // Toggle isChecked //
          : module
      )
    );
    console.log("save module: ", modules, moduleIds);
  };

  const handleSubmitManager = (event) => {
    debugger;
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (data.name !== "" && data.email !== "" && data.mobile !== "") {
        setModuleAccessModel(true);
        setShowManager(false);
      } else {
        setOpen(true);
        setMessage("Please Enter All Field");
      }
      setValidated(true);
    }
  };

  const updateModules = () => {
    const updatedModules = modules.map((module) => ({
      ...module,
      isChecked: false,
    }));
    setModules(updatedModules);
  };

  const handleSave = (event) => {
    debugger;
    event.preventDefault();
    // setModuleAccessModel(false);

    const selectedModuleIds = modules
      .filter((module) => module.isChecked)
      .map((module) => module.moduleId);

    setModulesIds(selectedModuleIds);
    //---------------start
    if (selectedModuleIds.length === 0) {
      setOpen(true); // Show error message
      setMessage("Please select at least one module.");
      return;
    }
    if (selectedModuleIds.length !== 0) {
      adduser();
    }

    // const payload = {
    //   userId: managerIds, // You should set `selectedUserId` when opening the module modal
    //   moduleIds: selectedModuleIds,
    // };

    // console.log("Payload for API:", payload);

    // UserServices.assignModule(payload)
    //   .then((response) => {
    //     setOpen(true);
    //     setMessage("Modules assigned successfully.");
    //     setModuleAccessModel(false); // Close the modal
    //   })
    //   .catch((error) => {
    //     setOpen(true);
    //     setMessage("Error assigning modules. Please try again.");
    //   });
    //---------------------------end-------------------------
  };

  const handleUpdateModuleSave = (event) => {
    debugger;
    event.preventDefault();
    // setModuleAccessModel(false);

    const selectedModuleIds = modules
      .filter((module) => module.isChecked)
      .map((module) => module.moduleId);

    setModulesIds(selectedModuleIds);
    //---------------start
    if (selectedModuleIds.length === 0) {
      setOpen(true); // Show error message
      setMessage("Please select at least one module.");
      return;
    }
    if (selectedModuleIds.length !== 0) {
      updateModuleAccess();
    }

    // const payload = {
    //   userId: managerIds, // You should set `selectedUserId` when opening the module modal
    //   moduleIds: selectedModuleIds,
    // };

    // console.log("Payload for API:", payload);

    // UserServices.assignModule(payload)
    //   .then((response) => {
    //     setOpen(true);
    //     setMessage("Modules assigned successfully.");
    //     setModuleAccessModel(false); // Close the modal
    //   })
    //   .catch((error) => {
    //     setOpen(true);
    //     setMessage("Error assigning modules. Please try again.");
    //   });
    //---------------------------end-------------------------
  };

  const updateModuleAccess = () => {
    setLoadingUpdateAssociation(true);
    const selectedModuleIds = modules
      .filter((module) => module.isChecked)
      .map((module) => module.moduleId);

    setModulesIds(selectedModuleIds);
    const payload = {
      userId: managerIds, // You should set `selectedUserId` when opening the module modal
      moduleIds: selectedModuleIds,
    };

    UserServices.updateModuleAccess(payload)
      .then((response) => {
        setLoadingUpdateAssociation(false);
        setOpen(true);
        setMessage("Module updated successfully");
        setUpdateModuleAccessModel(false);
      })
      .catch((error) => {
        setLoadingUpdateAssociation(false);
        console.log("error", error);
        if (error.response.status == 400) {
          setOpen(true);
          setMessage(error.response.data.message);
        }
        setLoad(false);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-lg-5">
                  <h3 className="mb-0 dash-heading">Manage Managers</h3>
                </div>
                <div className="col-lg-7 d-flex justify-content-end">
                  <div className="row">
                    <div className="col-lg-12 text-end">
                      <button
                        type="button"
                        className="btn btn-user"
                        onClick={handleManagerShow}
                      >
                        <i className="bi bi-person-plus"></i> Add Manager
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              {/* <div className="row mb-3">
                <div className="col-lg-3">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="roleVeiw"
                    value={roleVeiw}
                    onChange={handleRoleView}
                  >
                    <option value="">Select User Type</option>
                    <option value="3">Principal</option>
                    <option value="1">Distributor</option>
                  </select>
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-1"></div>
              </div> */}
              <div className="row my-3" role="tabpanel" aria-labelledby="1">
                <div className="max-width">
                  {loading ? (
                    <ServerSideTable
                      pageSize={10}
                      page={0}
                      count={count}
                      functionName={getCompanies}
                      data={[]}
                      columns={columns}
                      loading={true}
                      idname="id"
                    />
                  ) : (
                    <ServerSideTable
                      pageSize={10}
                      page={0}
                      count={count}
                      functionName={getCompanies}
                      data={count > 0 ? userData : []}
                      columns={columns}
                      loading={false}
                      idname="id"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /----------------Add-New-User------------------------/ */}

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid p-0">
            <div className="row">
              <h6 style={{ marginBottom: "15px", fontWeight: "700" }}>Role</h6>
              <div className="col-lg-5">
                <div className="">
                  <input
                    type="radio"
                    id="tab1"
                    name="roles"
                    value="3"
                    onChange={valueOnChange}
                  />
                  <label
                    for="tab1"
                    style={{ fontWeight: "bold", color: "#000" }}
                  >
                    Role Principal
                  </label>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="">
                  <input
                    type="radio"
                    id="tab2"
                    name="roles"
                    value="1"
                    onChange={valueOnChange}
                  />
                  <label
                    for="tab2"
                    style={{ fontWeight: "bold", color: "#000" }}
                  >
                    Role Distributor
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div class="login__form-wrapper">
                <Form noValidate validated={validated}>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Company Name
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="pass"
                      type="test "
                      placeholder="Enter Company Name"
                      name="companyName"
                      value={data.password}
                      onChange={valueOnChange}
                    />
                  </div>
                  <div class="login__field-group">
                    <label class="login__label_modal" for="pass">
                      Name
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="name"
                      type="text "
                      placeholder="Enter Name"
                      name="name"
                      value={data.name}
                      onChange={valueOnChange}
                    />
                  </div>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="user-email">
                      Email
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="email"
                      type="email"
                      placeholder="Enter Email ID"
                      name="email"
                      value={data.email}
                      onChange={valueOnChange}
                    />
                  </div>
                  <div className="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Select Country
                    </label>
                    <Form.Control
                      required
                      as="select"
                      className="login__field_modal mt-2 mb-3"
                      name="countryId"
                      onChange={valueOnChange}
                      value={country.name}
                    >
                      <option value="">Country List</option>
                      {countryName.map((countryOption) => (
                        <option key={countryOption.id} value={countryOption.id}>
                          {countryOption.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Phone No.
                    </label>
                    <div className="flex-y">
                      <Form.Control
                        as="select"
                        required
                        className="login__field_modal mt-2 f-20 mb-3"
                        name="phoneCode"
                        value={data.phoneCode}
                        onChange={valueOnChange}
                      >
                        <option value="">Ph. Code</option>
                        {countryCode.map((countryOption) => (
                          <option
                            key={countryOption.id}
                            value={countryOption.phonecode}
                          >
                            {countryOption.phonecode}
                          </option>
                        ))}
                      </Form.Control>

                      <Form.Control
                        required
                        className="login__field_modal f-75 mt-2 mb-3"
                        id="phn"
                        type="text"
                        maxLength={16}
                        placeholder="Enter Phone No."
                        name="mobile"
                        value={data.mobile}
                        onChange={valueOnChange}
                      />
                    </div>
                  </div>
                  {/* Country list dropdown */}
                </Form>
                <button
                  class="login__btn_modal"
                  type="button"
                  data-login="false"
                  onClick={handleSubmit}
                >
                  <span class="login__btn-label">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showManager} onHide={handleManagerClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid p-0">
            <div className="mt-3">
              <div class="login__form-wrapper">
                <Form noValidate validated={validated}>
                  <div class="login__field-group">
                    <label class="login__label_modal" for="pass">
                      Name
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="name"
                      type="text "
                      placeholder="Enter Name"
                      name="name"
                      value={data.name}
                      onChange={valueOnChange}
                    />
                  </div>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="user-email">
                      Email
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="email"
                      type="email"
                      placeholder="Enter Email ID"
                      name="email"
                      value={data.email}
                      onChange={valueOnChange}
                    />
                  </div>
                  <div className="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Select Country
                    </label>
                    <Form.Control
                      required
                      as="select"
                      className="login__field_modal mt-2 mb-3"
                      name="countryId"
                      onChange={valueOnChange}
                      value={country.name}
                    >
                      <option value="">Country List</option>
                      {countryName.map((countryOption) => (
                        <option key={countryOption.id} value={countryOption.id}>
                          {countryOption.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Phone No.
                    </label>
                    <div className="flex-y">
                      <Form.Control
                        as="select"
                        required
                        className="login__field_modal mt-2 f-20 mb-3"
                        name="phoneCode"
                        value={data.phoneCode}
                        onChange={valueOnChange}
                      >
                        <option value="">Ph. Code</option>
                        {countryCode.map((countryOption) => (
                          <option
                            key={countryOption.id}
                            value={countryOption.phonecode}
                          >
                            {countryOption.phonecode}
                          </option>
                        ))}
                      </Form.Control>

                      <Form.Control
                        required
                        className="login__field_modal f-75 mt-2 mb-3"
                        id="phn"
                        type="text"
                        maxLength={16}
                        placeholder="Enter Phone No."
                        name="mobile"
                        value={data.mobile}
                        onChange={valueOnChange}
                      />
                    </div>
                  </div>
                  {/* Country list dropdown */}
                </Form>
                <button
                  class="login__btn_modal"
                  type="button"
                  data-login="false"
                  onClick={handleSubmitManager}
                  disabled={loadingAddManager}
                >
                  {loadingAddManager ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Add Manager"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={moduleAccessModel}
        onHide={handleModuleAccessModelClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Module Access Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid p-0">
            <div className="mt-3">
              <Card variant="outlined" sx={{ p: 1 }}>
                <CardContent sx={{ p: 0 }}>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {modules.map((module, index) => (
                          <React.Fragment key={module.moduleId}>
                            <TableRow>
                              <TableCell sx={{ py: 0.5, px: 1 }}>
                                <Box display="flex" alignItems="center">
                                  {/* {module.icon} */}
                                  <ListItemText
                                    primary={module.moduleName}
                                    sx={{ ml: 1 }}
                                    primaryTypographyProps={{
                                      variant: "subtitle2",
                                    }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell align="center" sx={{ py: 0.5, px: 1 }}>
                                <Switch
                                  size="small"
                                  //checked={module.access}
                                  // checked={checkedModules.includes(
                                  //   module.moduleId
                                  // )}
                                  checked={module.isChecked}
                                  onChange={() =>
                                    handleToggleAccessButton(module.moduleId)
                                  }
                                  color="primary"
                                />
                              </TableCell>
                            </TableRow>
                            {index < modules.length - 1 && (
                              <TableRow>
                                <TableCell colSpan={2} sx={{ py: 0, px: 1 }}>
                                  <Divider />
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={loadingAddManager}
                    >
                      {loadingAddManager ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateModuleAccessModel}
        onHide={handleUpdateModuleAccessModelClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Module Access Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid p-0">
            <div className="mt-3">
              <Card variant="outlined" sx={{ p: 1 }}>
                <CardContent sx={{ p: 0 }}>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {modules.map((module, index) => (
                          <React.Fragment key={module.moduleId}>
                            <TableRow>
                              <TableCell sx={{ py: 0.5, px: 1 }}>
                                <Box display="flex" alignItems="center">
                                  {/* {module.icon} */}
                                  <ListItemText
                                    primary={module.moduleName}
                                    sx={{ ml: 1 }}
                                    primaryTypographyProps={{
                                      variant: "subtitle2",
                                    }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell align="center" sx={{ py: 0.5, px: 1 }}>
                                <Switch
                                  size="small"
                                  //checked={module.access}
                                  // checked={checkedModules.includes(
                                  //   module.moduleId
                                  // )}
                                  checked={module.isChecked}
                                  onChange={() =>
                                    handleToggleAccessButton(module.moduleId)
                                  }
                                  color="primary"
                                />
                              </TableCell>
                            </TableRow>
                            {index < modules.length - 1 && (
                              <TableRow>
                                <TableCell colSpan={2} sx={{ py: 0, px: 1 }}>
                                  <Divider />
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateModuleSave}
                      disabled={loadingUpdateAssociation}
                    >
                      {loadingUpdateAssociation ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* //---------------------------------Update-------------------------- */}
      <Modal show={updateShow} onHide={updateHandleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Update Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid p-0">
            {/* <div className="row">
              <div className="col-lg-4">
                <div className="">
                  <input
                    type="radio"
                    id="tab1"
                    name="roles"
                    value="3"
                    checked={
                      updateData?.roles[0] == undefined ||
                      updateData.roles == undefined
                        ? false
                        : updateData?.roles[0].id == "3"
                        ? true
                        : false
                    }
                    onChange={updatevalueOnChange}
                  />
                  <label
                    for="tab1"
                    style={{ fontWeight: "bold", color: "#000" }}
                  >
                    Principal
                  </label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="">
                  <input
                    type="radio"
                    id="tab2"
                    name="roles"
                    value="1"
                    checked={
                      updateData?.roles[0] == undefined ||
                      updateData.roles == undefined
                        ? false
                        : updateData?.roles[0].id == "1"
                        ? true
                        : false
                    }
                    onChange={updatevalueOnChange}
                  />
                  <label
                    for="tab2"
                    style={{ fontWeight: "bold", color: "#000" }}
                  >
                    Distributor
                  </label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=""></div>
              </div>
            </div> */}
            <form className="mt-3">
              <div class="login__form-wrapper">
                <Form noValidate validated={validated}>
                  <div class="login__field-group">
                    <label class="login__label_modal" for="pass">
                      Name
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="name"
                      type="text "
                      placeholder="Enter Name"
                      name="name"
                      value={updateData.name}
                      onChange={updatevalueOnChange}
                    />
                  </div>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="user-email">
                      Email
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2"
                      id="email"
                      type="email"
                      disabled
                      placeholder="Enter Email ID"
                      name="email"
                      value={updateData.email}
                      onChange={updatevalueOnChange}
                    />
                  </div>
                  {/* <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Phone No.
                    </label>
                    <Form.Control
                      required
                      className="login__field_modal mt-2 mb-3"
                      id="phn"
                      type="text"
                      maxLength={16}
                      pattern="\d*"
                      placeholder="Enter Phone No."
                      name="mobile"
                      value={updateData.mobile}
                      onChange={updatevalueOnChange}
                    />
                  </div> */}

                  <div className="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Select Country
                    </label>
                    <Form.Control
                      required
                      as="select"
                      className="login__field_modal mt-2 mb-3"
                      name="countryId"
                      onChange={updatevalueOnChange}
                      value={updateData.countryId}
                    >
                      <option value="">Country List</option>
                      {countryName.map((countryOption) => (
                        <option key={countryOption.id} value={countryOption.id}>
                          {countryOption.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div class="login__field-group mt-3">
                    <label class="login__label_modal" for="pass">
                      Phone No.
                    </label>
                    <div className="flex-y">
                      <Form.Control
                        as="select"
                        required
                        className="login__field_modal mt-2 f-20 mb-3"
                        name="phoneCode"
                        value={updateData.phoneCode}
                        onChange={updatevalueOnChange}
                      >
                        <option value="">Ph. Code</option>
                        {countryCode.map((countryOption) => (
                          <option
                            key={countryOption.id}
                            value={countryOption.phonecode}
                          >
                            {countryOption.phonecode}
                          </option>
                        ))}
                      </Form.Control>

                      <Form.Control
                        required
                        className="login__field_modal f-75 mt-2 mb-3"
                        id="phn"
                        type="text"
                        maxLength={16}
                        placeholder="Enter Phone No."
                        name="mobile"
                        value={updateData.mobile}
                        onChange={updatevalueOnChange}
                      />
                    </div>
                  </div>
                  {/* Country list dropdown */}
                </Form>
                <button
                  class="login__btn_modal"
                  type="button"
                  data-login="false"
                  onClick={updateHandleSubmit}
                  disabled={loadingUpdateUser}
                >
                  {loadingUpdateUser ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={associationShow}
        onHide={associationShowHandleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Association</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid p-0">
            <div style={{ display: "flex" }} className="mb-3">
              <h5>{userState?.name} </h5>
              <strong className="mt-1 ps-2 text-muted">
                {" "}
                {"["}
                {displayedRole === "ROLE_SUB_ADMIN" ? "Role - Manager" : ""}
                {"]"}
                {/* [{userState?.roles[0]?.name}] */}
              </strong>
            </div>

            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{customList(left, true)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5, mb: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5, mb: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList(right, false)}</Grid>
            </Grid>

            <div className="mt-3">
              <div class="login__form-wrapper">
                <button
                  class="login__btn_modal"
                  type="button"
                  data-login="false"
                  onClick={() => updateHandleSubmitAssoication(right)}
                  disabled={loadingUpdateModule}
                >
                  <span class="login__btn-label">
                    {loadingUpdateModule ? (
                      <>
                        <CircularProgress size={20} /> Updating
                      </>
                    ) : (
                      "Update"
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={alertClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={alertClose} severity="info" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete user "${deleteUser}"`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deletehandleClose}>Cancel</Button>
          <Button onClick={deletehandle} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Managers;
