import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DashboardService from "../dashboard/dashboardServices";
import jwt_decode from "jwt-decode";

function Dashboard() {
  const [show, setShow] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [complaintsData, setComplaintData] = useState([]);

  //recent udpated data states
  const [recentOrderData, setRecentOrderData] = useState([]);
  const [recentSampleData, setRecentSampleData] = useState([]);
  const [recentPaymentsData, setRecentPaymentsData] = useState([]);
  const [recentComplaintsData, setRecentComplaintData] = useState([]);
  const [modalId, setModalId] = useState();

  //dashboard count
  const [orderCount, setOrderCount] = useState(0);
  const [sampleCount, setSampleCount] = useState(0);
  const [salesCount, setSalesCount] = useState(0);
  const [queryCount, setQueryCount] = useState(0);

  // const readHandleClose = () => setShow(false);
  // const readHandleShow = () => setreadshow(true);
  const handleClose = () => {
    setShow(false);
  };
  //handleClick event to show modal
  const handleModal = (orderId) => {
    setShow(true);
    setModalId(orderId);
    console.log("Modal_item", orderId);
  };

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const userId = jwtDecode ? jwtDecode.Id : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;

  //handle latest data
  useEffect(() => {
    {
      if (role === "ROLE_DISTRIBUTER") {
        DashboardLatestService();
        DashboardRecentService();
        DistributorCount();
      } else if (role === "ROLE_CLIENT") {
        PrincipalLatestServices();
        PrincipalRecentServices();
        PrincipalCount();
      } else {
        AdminLatestServices();
        AdminRecentServices();
        AdminCount();
      }
    }
  }, [userId]);

  // const filehandleShow = (doc, name, date) => {
  //   setFileshow(true);
  //   setShippedDocList(doc);
  //   setShippedDocname(name);
  //   setShippedDocdate(date);
  // };

  const formatDateTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const formattedDay = String(dateObject.getDate()).padStart(2, "0");
    const formattedMonth = String(dateObject.getMonth() + 1).padStart(2, "0");
    const formattedYear = String(dateObject.getFullYear()).slice(-2);
    const formattedHours = String(dateObject.getHours()).padStart(2, "0");
    const formattedMinutes = String(dateObject.getMinutes()).padStart(2, "0");
    return `${formattedDay}/${formattedMonth}/${formattedYear} ${formattedHours}:${formattedMinutes}`;
  };
  //-----------------------Dashboard Count functionlity-------------------
  //distributor count
  const DistributorCount = async () => {
    await DashboardService.getDistributorCount(userId).then((response) => {
      const data = response.data.data;
      console.log("distributor_count_data", data);
      setOrderCount(data.totalOrder);
      setSampleCount(data.totalSample);
      setQueryCount(data.totalQuery);
      setSalesCount(data.totalComplaint);
    });
  };
  //principal count
  const PrincipalCount = async () => {
    await DashboardService.getPrincipalCount(userId).then((response) => {
      const data = response.data.data;
      console.log("distributor_count_data", data);
      setOrderCount(data.totalOrder);
      setSampleCount(data.totalSample);
      setQueryCount(data.totalQuery);
      setSalesCount(data.totalComplaint);
    });
  };
  //admin count
  const AdminCount = async () => {
    await DashboardService.getAdminCount().then((response) => {
      const data = response.data.data;
      console.log("distributor_count_data", data);
      setOrderCount(data.totalOrder);
      setSampleCount(data.totalSample);
      setQueryCount(data.totalQuery);
      setSalesCount(data.totalComplaint);
    });
  };

  const DashboardLatestService = async () => {
    await DashboardService.getLatestData(userId).then((response) => {
      const data = response.data.data;
      console.log("resp_data", data);
      setOrderData(data.orders);
      setPaymentsData(data.payments);
      setSampleData(data.samples);
      setComplaintData(data.complaints);
      console.log("data_payments", data.orders);
    });
  };
  const DashboardRecentService = async () => {
    await DashboardService.getRecentData(userId).then((response) => {
      const data = response.data.data;
      setRecentOrderData(data.recentOrders);
      console.log("recentOrders", data.recentOrders);
      setRecentPaymentsData(data.recentPayments);
      setRecentSampleData(data.recentSamples);
      setRecentComplaintData(data.recentComplaints);
    });
  };
  //Principal Services
  const PrincipalLatestServices = async () => {
    console.log("principal_userID", userId);
    try {
      const response = await DashboardService.getPrincipalLatest(userId);
      const data = response.data.data;
      console.log("latest_data_principal", data);
      setOrderData(data.orders);
      setPaymentsData(data.payments);
      setSampleData(data.samples);
      setComplaintData(data.complaints);
    } catch (error) {
      console.error("Error fetching principal latest data:", error);
    }
  };

  const PrincipalRecentServices = async () => {
    console.log("principal_userID", userId);
    try {
      const response = await DashboardService.getPrincipalRecent(userId);
      const data = response.data.data;
      console.log("recent_data_principal", data);
      setRecentOrderData(data.recentOrders);
      console.log("recentOrders", data.recentOrders);
      setRecentPaymentsData(data.recentPayments);
      setRecentSampleData(data.recentSamples);
      setRecentComplaintData(data.recentComplaints);
    } catch (error) {
      console.error("Error fetching principal recent data:", error);
    }
  };
  //Admin Services
  const AdminLatestServices = async () => {
    try {
      const response = await DashboardService.getAdminLatest();
      const data = response.data.data;
      console.log("latest_data_admin", data);
      setOrderData(data.orders);
      setPaymentsData(data.payments);
      setSampleData(data.samples);
      setComplaintData(data.complaints);
    } catch (error) {
      console.error("Error fetching principal latest data:", error);
    }
  };

  const AdminRecentServices = async () => {
    try {
      const response = await DashboardService.getAdminRecent();
      const data = response.data.data;
      console.log("recent_data_admin", data);
      setRecentOrderData(data.recentOrders);
      console.log("recentOrders", data.recentOrders);
      setRecentPaymentsData(data.recentPayments);
      setRecentSampleData(data.recentSamples);
      setRecentComplaintData(data.recentComplaints);
    } catch (error) {
      console.error("Error fetching principal recent data:", error);
    }
  };
  // const handleUploadFile = (e) => {
  //    ;
  //   // const id = prop.data.id;
  //   const file = e.target.files[0]; // getting the file which is being uploaded

  //   try {
  //     console.log("fileInput", file);
  //     console.log("order_id", id);
  //     UserServices.uploadOrderFile(id, file)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           toast.success("File uploaded successfully");
  //           prop.getFunction();
  //         } else {
  //           toast.error("Error while uploading file");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         toast.error("Error while uploading file");
  //       });
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     toast.error("Error while uploading file");
  //   }
  // };
  function getStatusClassName(status) {
    console.log("status", status);
    switch (status) {
      case "PENDING":
        return "pending";
      case "REJECTED":
        return "reject";
      case "RESOLVED":
        return "resolved";
      case "APPROVED":
        return "approved";
      case "CREDIT_RECEIVED":
        return "creditReceived";
      default:
        return "shipped";
    }
  }

  return (
    <>
      {role === "ROLE_DISTRIBUTER" ? (
        <>
          {" "}
          {/* first row */}
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 home-box mt-4">
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Orders
                  </p>
                  <h3 class="text-white text-end mb-0">{orderCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Samples
                  </p>
                  <h3 class="text-white text-end mb-0">{sampleCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Tech. Queries
                  </p>
                  <h3 class="text-white text-end mb-0">{queryCount}</h3>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Complaints
                  </p>
                  <h3 class="text-white text-end mb-0">{salesCount}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <hr />
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Order</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {orderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Sample</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {sampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* second row --- Latest data */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Orders</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentOrderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.orderStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Samples</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentSampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.sampleStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* third row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Payments</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {paymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Complaints</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {complaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Fourth Row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Payments</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentPaymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Complaints</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentComplaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(
                                  item.complaintStatuses[0].updatedAt
                                )}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal
            show={show}
            size="lg"
            onHide={readHandleClose}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>#{modalId} Order Details</Modal.Title>
            </Modal.Header>
            {orderData.map((item) => {
              <Modal.Body>
                <Form noValidate>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>
                        <b>Principal</b>
                      </Form.Label>
                      <Form.Control
                        value={item.distributer}
                        disabled
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Form.Label>
                        <b>PO Number</b>
                      </Form.Label>

                      <Form.Control
                        value={item.poNumber}
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>
                        <b>Performa Invoive</b>
                      </Form.Label>
                      <Form.Control disabled></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Form.Label>
                        <b>Inco Term</b>
                      </Form.Label>
                      <Form.Control
                        value={item.incoTerms}
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>
                        <b>Insurance</b>
                      </Form.Label>
                      <Form.Control
                        value={item.insurance}
                        disabled
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Form.Label>
                        <b>Freight</b>
                      </Form.Label>
                      <Form.Control
                        value={item.freight}
                        disabled
                      ></Form.Control>
                      <Form.Control disabled></Form.Control>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group as={Col} md="12">
                        <Form.Label>
                          <b>Special Requirements</b>
                        </Form.Label>

                        <div className="file-input">
                          <label className="file-input__label" for="file-input">
                            <input
                              type="file"
                              id="multi-file-input"
                              class="file-input__input"
                              // onChange={handleUploadFile}
                            />
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="upload"
                              class="svg-inline--fa fa-upload fa-w-16"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                              ></path>
                            </svg>
                            <span>Upload file</span>
                          </label>
                        </div>
                        <br />
                        <div
                          className="files"
                          id="fileList"
                          // style={
                          //   fileCount > 0 ? { display: "block" } : { display: "none" }
                          // }
                        >
                          <h5>Files Selected</h5>
                          <ul className="Filelisting"></ul>
                        </div>

                        <p>
                          <Form.Label>
                            <b>Uploaded Documents</b>
                          </Form.Label>
                        </p>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mt-3">
                      <Form.Label>
                        <b>Track Status</b>
                      </Form.Label>
                      <div class="history-tl-container">
                        <ul class=" tl virtical"></ul>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>;
            })}

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
        </>
      ) : role === "ROLE_CLIENT" ? (
        <>
          {" "}
          {/* first row */}
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 home-box mt-4">
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Orders
                  </p>
                  <h3 class="text-white text-end mb-0">{orderCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Samples
                  </p>
                  <h3 class="text-white text-end mb-0">{sampleCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Tech. Queries
                  </p>
                  <h3 class="text-white text-end mb-0">{queryCount}</h3>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Complaints
                  </p>
                  <h3 class="text-white text-end mb-0">{salesCount}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <hr />
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Order</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {orderData &&
                            orderData.map((item) => (
                              <tr key={item.id}>
                                <td
                                  className="cursor-pointer"
                                  onClick={() => handleModal(item.id)}
                                >
                                  #{item.id}
                                </td>
                                <td>{item.poNumber}</td>
                                <td>{item.distributer}</td>
                                <td>{formatDateTime(item.createdAt)}</td>
                                <td
                                  className="status"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item.currentOrderStatus}
                                >
                                  <div
                                    className={getStatusClassName(
                                      item.currentOrderStatus
                                    )}
                                  ></div>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Sample</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {sampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* second row --- Latest data */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Orders</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentOrderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.orderStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Samples</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentSampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.sampleStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* third row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Payments</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {paymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.distributor}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Complaints</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {complaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.distributor}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Fourth Row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Payments</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentPaymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.distributor}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Complaints</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Distributor</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentComplaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.distributor}</td>
                              <td>
                                {formatDateTime(
                                  item.complaintStatuses[0].updatedAt
                                )}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : role === "ROLE_ADMIN" ? (
        <>
          {/* first row */}
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 home-box mt-4">
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Orders
                  </p>
                  <h3 class="text-white text-end mb-0">{orderCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Samples
                  </p>
                  <h3 class="text-white text-end mb-0">{sampleCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Tech. Queries
                  </p>
                  <h3 class="text-white text-end mb-0">{queryCount}</h3>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Complaints
                  </p>
                  <h3 class="text-white text-end mb-0">{salesCount}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <hr />
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Order</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {orderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Sample</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {sampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* second row --- Latest data */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Orders</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentOrderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.orderStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Samples</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentSampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.sampleStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* third row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Payments</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {paymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Complaints</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {complaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Fourth Row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Payments</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentPaymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Complaints</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentComplaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(
                                  item.complaintStatuses[0].updatedAt
                                )}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* first row */}
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 home-box mt-4">
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Orders
                  </p>
                  <h3 class="text-white text-end mb-0">{orderCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Samples
                  </p>
                  <h3 class="text-white text-end mb-0">{sampleCount}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Tech. Queries
                  </p>
                  <h3 class="text-white text-end mb-0">{queryCount}</h3>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card radius-10">
                <div class="card-body py-3 px-3">
                  <p class="mt-1 text-white font-20 text-left mb-0">
                    Total Complaints
                  </p>
                  <h3 class="text-white text-end mb-0">{salesCount}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <hr />
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Order</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {orderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Sample</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {sampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* second row --- Latest data */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Orders</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentOrderData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.id)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.poNumber}</td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.orderStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentOrderStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentOrderStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Samples</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {recentSampleData.map((item) => (
                            <tr key={item.id}>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.id}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(item.sampleStatus[0].updatedAt)}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentSampleStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentSampleStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* third row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Payments</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {paymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Latest Complaints</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                          {complaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.createdAt)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Fourth Row */}
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Payments</h3>
                </div>

                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Payment ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentPaymentsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>{formatDateTime(item.paymentDate)}</td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentPaymentStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentPaymentStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 ">
              <div class="card w-100 rounded-4 dashboard">
                <div className="card-header">
                  <h3 class="mb-0 dash-heading">Recently Updated Complaints</h3>
                </div>
                <div class="card-body px-3 py-3">
                  <div class="row">
                    <div class="col-12">
                      <div className="datatable ">
                        <table>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Order ID</th>
                            <th>Principal</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>

                          {recentComplaintsData.map((item) => (
                            <tr key={item.id}>
                              <td>#{item.id}</td>
                              <td
                                className="cursor-pointer"
                                onClick={() => handleModal(item.orderId)}
                              >
                                #{item.orderId}
                              </td>
                              <td>{item.userPrinciple.name}</td>
                              <td>
                                {formatDateTime(
                                  item.complaintStatuses[0].updatedAt
                                )}
                              </td>
                              <td
                                className="status"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.currentComplaintStatus}
                              >
                                <div
                                  className={getStatusClassName(
                                    item.currentComplaintStatus
                                  )}
                                ></div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Dashboard;
